<template>
  <div
    v-if="showAdminBar"
    class="bg-white border-b border-b-stone-100 hidden md:block"
  >
    <VuepalAdminToolbar :key="language" />

    <div class="px-6">
      <div class="flex py-4 justify-between items-center min-h-[90px] gap-5">
        <VuepalLocalTasks class="mr-auto" />

        <button @click.prevent="showGrid = !showGrid">Toggle Grid</button>
        <button @click.prevent="showSettings = !showSettings">
          Toggle settings
        </button>
        <a :href="prodUrl" target="_blank">Show on Prod</a>
        <div class="px-2 py-1 bg-stone-50">Viewport: {{ currentViewport }}</div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <div
      v-if="showSettings"
      class="fixed bottom-0 right-0 bg-white shadow-lg p-7 z-[99999] border border-stone-500 hidden md:block"
    >
      <div class="flex gap-5">
        <AdminBarCssVar
          label="Normal"
          name="weight-normal"
          init="380"
          min="300"
          max="500"
        />
        <AdminBarCssVar
          label="Bold"
          name="weight-bold"
          min="500"
          max="900"
          init="600"
        />
        <AdminBarCssVar
          label="Line Height"
          name="line-height"
          min="1.2"
          max="1.8"
          init="1.4"
          step="0.01"
        />
      </div>
    </div>
    <div
      v-if="showGrid"
      class="fixed top-0 left-0 w-screen h-screen pointer-events-none overflow-y-scroll z-[999999]"
    >
      <div class="container container-grid h-full">
        <div
          v-for="n in 12"
          :key="n"
          class="h-full bg-red/15 flex items-end text-center relative"
        >
          <div class="relative z-10 font-bold text-3xl text-red w-full">
            {{ n }}
          </div>
        </div>
      </div>

      <div
        class="absolute left-0 w-full bg-red h-[1px] top-1/2 -translate-y-1/2"
      />
    </div>

    <div
      v-if="showGrid"
      class="absolute inset-0 pointer-events-none grid-overlay z-[999999]"
    />
  </Teleport>
</template>

<script setup lang="ts">
import { useLocalStorage, onKeyStroke, useWindowSize } from '@vueuse/core'

const route = useRoute()
const language = useCurrentLanguage()
const showGrid = useLocalStorage('show_grid', false)
const showSettings = useLocalStorage('show_settings', false)
const showAdminBar = useLocalStorage('show_admin_bar', true)
const { breakpoints } = useTailwind()

const prodUrl = computed(() => 'https://museum-gestaltung.ch' + route.path)

const { width } = useWindowSize()

const breakpointsSorted = computed(() => {
  return Object.entries(breakpoints)
    .map(([key, minWidth]) => {
      return {
        key,
        minWidth,
      }
    })
    .sort((a, b) => b.minWidth - a.minWidth)
})

const currentViewport = computed(() => {
  const match = breakpointsSorted.value.find(
    (v) => width.value >= v.minWidth,
  )?.key
  if (match) {
    return match
  }

  return breakpointsSorted.value[0].key
})

onKeyStroke(['g'], (e) => {
  if (!e.ctrlKey) {
    return
  }
  e.preventDefault()
  showGrid.value = !showGrid.value
})

onKeyStroke(['m'], (e) => {
  if (!e.ctrlKey) {
    return
  }
  e.preventDefault()
  showAdminBar.value = !showAdminBar.value
})
</script>

<style lang="postcss">
html {
  @apply relative;
}
.grid-overlay {
  background-image: url('~/assets/admin/grid-overlay.svg');
  background-repeat: repeat;
  background-size: 20px 16px;
}

.vuepal-admin-toolbar {
  @apply h-[70px];
}
</style>
