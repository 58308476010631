<template>
  <div>
    <div class="flex justify-between items-center h-8">
      <span>{{ label }}: {{ value }}</span>
      <button v-if="init !== value" @click="onReset">Reset</button>
    </div>
    <input
      :id="name"
      v-model="value"
      type="range"
      :name="name"
      :step="step"
      :min="min"
      :max="max"
      class="block w-[300px]"
    />
  </div>
</template>

<script lang="ts" setup>
import { useCssVar, useLocalStorage } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    label: string
    name: string
    min: string
    max: string
    step?: string
    init: string
  }>(),
  {
    step: '10',
  },
)

const cssVarName = computed(() => '--m-' + props.name)

const persisted = useLocalStorage(cssVarName.value, '')

const value = useCssVar(cssVarName)

function onReset() {
  value.value = props.init
}

watch(value, function (newValue) {
  persisted.value = newValue
})

onMounted(() => {
  if (!persisted.value) {
    return
  }

  value.value = persisted.value
})
</script>
